import React from 'react';
import { Route, Routes, Link } from 'react-router-dom';

import LoginComponent from './LoginComponent';
import ServitechLogoColor from './svg/ServiTechColor.svg'
import { EmployeeManager } from './employeeManagement';


import './styles.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { clearEmailToken, clearAccessToken } from './services/User';

const AppWrapper = () => {

    const handleLogOut = async (e) => {
        e.preventDefault()
        clearEmailToken()
        clearAccessToken()
    }

    return (
        <div style={{ background: "rgb(240,242,244", height: "100vh" }}>
            <nav className="navbar ST-navbar fixed-top">
                <div className="container-fluid">
                    <div>
                        {/* eslint-disable-next-line */}
                        <a className="navbar-brand" href="#"><img src={ServitechLogoColor} height="35px" /></a>
                    </div>
                    <div className="service-title">Employee Manager</div>
                    <div className="ps-5 pe-0">
                        {/* eslint-disable-next-line */}
                        <a className="navbar-nav" data-bs-toggle="dropdown">
                            <i className="bi bi-three-dots" style={{ "fontSize": "1.5rem", "color": "#000000", "cursor": "pointer" }}></i>
                        </a>
                        <ul className="me-0 dropdown-menu dropdown-menu-end">
                            <li><a className="dropdown-item" href="https://directory.servitech.com">Employee Directory</a></li>
                            <li><a className="dropdown-item" href="https://cropfile.servitech.com">The Crop File</a></li>
                            <li><a className="dropdown-item" href="https://labreports.servitechlabs.com/">Lab Reports</a></li>
                            <li><a className="dropdown-item" href="https://intranet.servitech.com">ServiTech Intranet</a></li>


                            <li><hr className="dropdown-divider" /></li>
                            <li className="dropdown-item" onClick={(e) => handleLogOut(e)}><Link to="/login" style={{ color: "black", textDecoration: "none" }}>Logout</Link></li>

                        </ul>
                    </div>
                </div>
            </nav>
            <div className="content-routes">
                <Routes>
                    <Route exact path="login" element={<LoginComponent />} />
                    <Route exact path="/" element={<EmployeeManager />} />
                </Routes>
            </div>
        </div>
    );
}

export default AppWrapper;
