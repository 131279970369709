import React from "react";
import EmployeeManagerInterface from "./EmployeeManagerInterface";
import { Navigate } from "react-router-dom";
import { getAccessToken, getEmail } from '../../services/User';

// const tabs = [
//     {
//         key: 'employeeManager',
//         label: 'Employee Manager'
//     }
// ];

const EmployeeManager = () => {
    // const [activeTab, setActiveTab] = useState('employeeManager');
    const activeTab = 'employeeManager'
    const token = getAccessToken();
    const userEmail = getEmail();

    if (token === null || token === '') {
        return <Navigate to='/login' />
    }

    return (
        <div>
            {activeTab === 'employeeManager' && (
                <EmployeeManagerInterface />
            )}
        </div>
    )
}

export default EmployeeManager;